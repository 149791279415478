import React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo"

export default () => (
  <>
    <SEO title="Not Found" />
    <div className="container full no-margin">
      <div className="container textCenter">
        <h2>Page Not Found</h2>
        <p>
          Looks like you've followed a broken link or entered a URL that doesn't
          exist.
        </p>
        <p>
          <Link to="/">Back to our site</Link>
        </p>
      </div>
    </div>
  </>
)
